export const STOCK_EVENT_TYPE = {
    // for direct inventory
    directInventoryAdd: 'DIRECT_INVENTORY_ADD',
    directInventoryUpdate: 'DIRECT_INVENTORY_UPDATE',
    directInventoryTransferFrom: 'DIRECT_INVENTORY_TRANSFER_FROM',
    directInventoryTransferTo: 'DIRECT_INVENTORY_TRANSFER_TO',
    directInventoryDiscard: 'DIRECT_INVENTORY_DISCARD',

    // for move inventory
    moveInventoryUpdate: 'MOVE_INVENTORY_UPDATE',
    moveInventoryTransferFrom: 'MOVE_INVENTORY_TRANSFER_FROM',
    moveInventoryTransferTo: 'MOVE_INVENTORY_TRANSFER_TO',

    // for offline order
    offlineOrderConsume: 'OFFLINE ORDER CONSUME',
    offlineOrderReturn: 'OFFLINE ORDER RETURN',

    // for manufacture procurement
    manufactureProcurementConsume: 'MANUFACTURE_PROCUREMENT_CONSUME',
    manufactureProcurementReturn: 'MANUFACTURE_PROCUREMENT_RETURN',
    manufactureProcurementUpdate: 'MANUFACTURE_PROCUREMENT_UPDATE',
    manufactureProcurementAdd: 'MANUFACTURE_PROCUREMENT_ADD',
    manufactureProcurementTransferFrom: 'MANUFACTURE_PROCUREMENT_TRANSFER_FROM',
    manufactureProcurementTransferTo: 'MANUFACTURE_PROCUREMENT_TRANSFER_TO',
    manufactureProcurementReduce: 'MANUFACTURE_PROCUREMENT_REDUCE',
    manufactureProcurementDiscard: 'MANUFACTURE_PROCUREMENT_DISCARD',

    // for vendor procurement
    vendorProcurementUpdate: 'VENDOR_PROCUREMENT_UPDATE',
    vendorProcurementAdd: 'VENDOR_PROCUREMENT_ADD',
    vendorProcurementReturn: 'VENDOR_PROCUREMENT_RETURN',
    vendorProcurementTransferFrom: 'VENDOR_PROCUREMENT_TRANSFER_FROM',
    vendorProcurementTransferTo: 'VENDOR_PROCUREMENT_TRANSFER_TO',
}